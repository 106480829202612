/* navbar css */

// .navbar {
//   .landing-container {
//     border-bottom: 1px solid #dbdbdb;
//     padding: 2rem 0;
//   }
// }

.navbar {
  position: sticky;
  top: 0;
  background-color: white; /* Add your desired background color */
  z-index: 1000; /* Add a high z-index value to make sure it's above other elements */

  .landing-container {
    border-bottom: 1px solid #dbdbdb;
    padding: 1.2rem 0;
  }
}

.navbar-container {
    position: relative;
  z-index: 1000;
  flex-basis: 0%;
  flex-grow: 0;
  @media screen and (max-width: 991px) {
    flex-basis: 100%;
    flex-grow: 1;
    .navlist {
      text-align: center;
    }
    button {
      margin: auto;
    }
  }
}
.nav-btn {
  background-color: var(--primary-blue);
  border: none;
  color: white;
  border-radius: 20px;
  padding: 0 1.5rem;
  margin: 0 0.5rem;
  font-weight: 500;
  @media screen and (max-width: 991px) {
    width: 10rem;
    height: 3rem;
  }
}
.nav-link {
  margin: 0 0.5rem;
  font-size: 16px;
}
.section {
  width: 100%;
}

/* home css */

.home-section {
  width: 100%;
  display: flex;
  padding: 4rem 0;
  align-items: center;
  justify-content: space-between;
  //   flex-direction: column;
}
.home_img {
  width: 170px;
  height: 170px;
  position: relative;
}

.btn-section {
  position: relative;
  .home_star_img {
    width: 110px;
    height: 110px;
    position: absolute;
    right: 5%;
    bottom: 5%;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
}

.home_img.left {
  top: 3rem;
}
.home_img.right {
  bottom: 3rem;
}
.home_title {
  text-align: center;
  font-size: 48px;
  padding: 50px;
}
.btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2rem;
}
.btn-container_first-btn {
  background-color: var(--primary-gray);
  border: none;
  color: black;
  border-radius: 30px;
  padding: 1rem 2rem;
  font-size: 18px;
  font-weight: 600;
}
.btn-container_first-btn:hover {
  background-color: var(--primary-gray);
  border: none;
  color: black;
}
.btn-container_second-btn {
  background-color: var(--primary-blue);
  border: none;
  color: white;
  border-radius: 30px;
  padding: 1rem 2rem;
  font-size: 18px;
  font-weight: 600;
}

.sub-text {
  color: #367ada;
}

/* about us css */

.aboutus-section {
  padding: 4rem 6rem 11rem;
  background-color: var(--dark-blue);
  display: flex;
  justify-content: center;
  align-items: inherit;
  gap: 2rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 991px) {
    align-items: center;
    flex-direction: column-reverse;
    padding-bottom: 6rem;
  }
}

.aboutus_left-container {
  width: 50%;
  padding: 4rem 0;
  padding-left: 5rem;
  @media screen and (max-width: 991px) {
    width: 100%;
    padding: 5rem 2rem;
  }
}
.aboutus_right-container {
  width: 50%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 991px) {
    width: 100%;
    justify-content: center;
    height: 35rem;
  }
}
.aboutus-title {
  color: white;
  font-size: 40px;
  font-weight: 700;
}
.aboutus-desc {
  color: white;
  font-size: 18px;
  font-weight: 400;
}
.aboutus-btn {
  border: none;
  outline: none;
  background-color: var(--primary-blue);
  color: white;
  border-radius: 30px;
  padding: 1rem 1.5rem;
  font-size: 18px;
  font-weight: 700;
}
#aboutus .logo-overlay-one {
  background-color: var(--primary-blue);
  width: 90%;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  height:60%;
}
 #aboutus .logo-overlay-two {
  border-radius: 10px;
  position: relative;
  background-color: rgb(255, 255, 255);
  width: 90%;
  height: 90%;
  top: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
} 
#aboutus .logo-overlay-two img {
  border-radius: 10px;
  position: relative;
  background-color: rgb(255, 255, 255);
  width: 37%;
  height: 19%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* commitment css */

.commitment-section {
  width: 65%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem 5rem;
  border-bottom: 1px solid #dbdbdb;
}

.commitment-title {
  color: #212326;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  margin: 1rem 0;
}
.commitment-desc {
  color: #797979;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin: 1rem 0;
}
.commitment-btn {
  border: none;
  background-color: var(--primary-blue);
  color: white;
  border-radius: 30px;
  padding: 1rem 1.5rem;
  font-size: 18px;
  font-weight: 700;
  margin: 1rem 0;
}

/* features css */
.features-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
}
.features-title {
  font-size: 40px;
  font-weight: 700;
}
.features-container {
  padding: 3rem 2rem 9rem;
  display: flex;
  flex-wrap: nowrap;
  gap: 5rem;
  justify-content: center;
  width: 100%;
}
.feature {
  background-color: #d9d9d9;
  border-radius: 20px;
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  min-width: 330px;
}
.feature-img {
  border-radius: 20px;
}
.feature-title {
  font-size: 30px;
  font-weight: 500;
  margin: 0.5rem 0;
}
.feature-desc {
  color: #797979;
  font-size: 18px;
  font-weight: 400;
}
.readmore {
  color: #7782ff;
}

/* why schoose us css */

#choose .logo-overlay-one {
  background-color: var(--primary-blue);
  width: 90%;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  height: 90%;
}
 #choose .logo-overlay-two {
  border-radius: 10px;
  position: relative;
  background-color: rgb(255, 255, 255);
  width: 80%;
  height: 100%;
  top: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#choose .logo-overlay-two img {
  border-radius: 10px;
  position: relative;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  top: -10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.choose-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eef2f3;
  padding: 5rem 0 10rem;
}

.choose-title {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 5rem;
}

.chooseus-container {
  display: flex;
  justify-content: center;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
}
.chooseus-left {
  width: 45%;
  @media screen and (max-width: 991px) {
    width: 95%;
    height: 35rem;
    display: flex;
    justify-content: center;
  }
}
.chooseus-right {
  width: 45%;
  @media screen and (max-width: 991px) {
    width: 95%;
  }
}
.faq {
  display: flex;
  margin-top: 30px;
}
.faq-icon {
  height: 100px;
  width: 100px;
}
.faq-title {
  font-size: 30px;
  font-weight: 500;
}
.faq-desc {
  font-weight: 400;
  font-size: 18px;
  color: #797979;
}

/* contact */

.contact-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
}
.contact-title {
  font-size: 40px;
  font-weight: 700;
  padding: 2rem 0;
}
.form-container {
  width: 60%;
  background-color: #deecff;
  border-radius: 24px;
  padding: 2rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-flex {
  display: flex;
  width: 100%;
}
.form-control {
  width: 100%;
  background-color: transparent;
  border: none;
  p {
    margin-bottom: 5px;
  }
}
.input {
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 10px;
  padding: 0 1rem;
}
.input:focus {
  outline: none;
}
.form-control > p {
  font-size: 18px;
  font-weight: 500;
}
.submit-btn {
  background-color: var(--primary-blue);
  border: none;
  color: white;
  border-radius: 30px;
  padding: 1rem 4rem;
  font-size: 18px;
  font-weight: 600;
  margin-top: 2rem;
}

/* footer css */

.footer_p {
  
  font-size: 15px;
  // font-weight: 600;
  margin:20px 15px;
  padding: 20px;
  text-decoration: none;
  line-height: 1.7;
}

.copyright {
  font-size: 18px;
  padding-left: 40px;

}

.footer_p span {
font-weight: 600;
font-size: 17px;
}

footer a {
  text-decoration: none;
}

.footer {
  background-color: #eef2f3;

}

/* media query */

@media screen and (max-width: 750px) {
  .form-container {
    width: 90%;
    padding: 2rem 2rem;
  }
  .form-flex {
    flex-direction: column;
  }
}
