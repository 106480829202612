/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;600&display=swap");

:root {
  --primary-blue: #367ada;
  --primary-gray: #d9d9d9;
  --dark-blue: #292d39;
}

body {
  /* font-family: "Inter", sans-serif; */
  font-family: "Fira Sans", Arial, sans-serif;
  margin: 0;
  padding: 0;
}
